

// --------------------------
// Header
// --------------------------
.links {
  text-decoration: none;
  color: inherit;
}
nav {
  display: flex;
  justify-content: space-between;
width: 100%;
float: right;
z-index: -1;
// position: -webkit-sticky;
// 	position: sticky;
// 	top: 0;
img {
  height:60px;
  margin-left: 15px;
  filter: drop-shadow(0 0 0.75rem rgb(0, 0, 160));
  
}
.menuIcon {
    transform: scale(1.5);

}

    div {
      margin: 10px;
      font-size: large;
    }
  }

// -------------
// Background
//--------------
.imageContainer {
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    // width: 100%;
    // max-height: 100vh;
    position: fixed;
    z-index: -1;
}
.bgImage {
    width: 100%;
    height: 100%;
    background-size: contain;
    
    filter: brightness(90%);
    filter: saturate(175%);
}
.homePage {
    width: 100%;
    height: 70vh;
    display:flex;
    justify-content: center;
    align-items: center;
}
.olContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height:fit-content;
  margin-top: 40px;
}
.overlay {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    background-color: rgba(0,0, 0,.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    color: white;
    text-align: center;
    padding: 20px 10px 20px 10px;
}
.line {
  height:80px;
  width: 0px;
  border: 2px solid white;
}
.CTA {
  height:60px;
  width: fit-content;
  padding: 20px;
  background-color: rgba(0,0, 0,.5);
  color: white;
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
 h5 {
   margin: 0;
 }
}
.aboutContainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
 overflow: auto;
 z-index: 1;
 margin: 0;

}
.IndCont {
  display:flex;
  justify-content: center;
  align-items: center;
}
  .indImg {
    display:flex;
    justify-content: center;
    width:35%;
    transform: rotate(-2deg);
  }
  .title {
    text-shadow: black 1px 1px;
    margin-bottom: 5px;
  }


.contactPage{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
